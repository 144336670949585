import axios from 'axios';
import { STORAGE } from './storage';
import { USER_ROLES } from '@/models/user';

let last_subdomain = window.location.hostname.split('.')[0];
const STAGE = 'dev';

export const DOMAIN_PROD	= 'paywall.pw';
export const DOMAIN_LIST 	= ['merchant', 'partner', 'admin', 'paywall'];
export const LAST_SUBDOMAIN = DOMAIN_LIST.includes(last_subdomain) ? null : last_subdomain;
export const PORT			= window.location.port !== '' ? ':' + window.location.port : '';
export const DOMAIN 		= window.location.hostname.split('.').slice(-2).join('.');
export const ISPROD			= window.location.hostname == DOMAIN_PROD || DOMAIN_LIST.includes(window.location.hostname.replace('paywall.pw', '').replace('.', ''));
export const ISFEATURE		= window.location.hostname.includes('feature');
export const ISDEV			= window.location.hostname.includes('dev\.');
export const SERVER_NAME	= window.location.hostname.includes('paywall.vm') ? STAGE : window.location.hostname.split('.')[0];

const APIURL_PROD 			= `${window.location.protocol}//${ISPROD ? '' : (LAST_SUBDOMAIN != null ? LAST_SUBDOMAIN + '.' : '')}api.${DOMAIN}${PORT}/`;
const APIURL_LOCAL			= `https://${STAGE}.api.paywall.pw/`;
export const APIURL=feature-cicd.stage.api.paywall.pw;
export const MERCHANT_URL=feature-cicd.stage.merchant.paywall.pw;
export const PARTNER_URL=feature-cicd.stage.partner.paywall.pw;
export const ADMIN_URL=feature-cicd.stage.admin.paywall.pw;

export function getSPARole () {
	if ( window.location.href.includes('partner\.') ) return USER_ROLES.PARTNER;

	return USER_ROLES.MERCHANT;
}
export const API = {
	URL: APIURL,

	items(data = {}) {
		return axios.get(`${this.URL}`, {params: {...data}});
	},
	id(id) {
		return axios.get(`${this.URL}/${id}.jsonld`);
	},
	add(data) {
		return axios.post(`${APIURL}plain_projects`, {...data});
	},
	addNeuro(data) {
		return axios.post(`${APIURL}neuro_projects`, {...data});
	},
	addAccounts(data) {
		return axios.post(`${this.URL}`, {...data});
	},
	addPlans(data) {
		return axios.post(`${APIURL}plain_plans`, {...data});
	},
	edit(data) {
		let id = data.id;
		delete data.id;

		return axios.put(`${APIURL}plain_plans/${id}.jsonld`, {...data});
	},
	editProject(data) {
		let id = data.id;
		delete data.id;

		return axios.put(`${APIURL}projects/${id}.jsonld`, {...data});
	},
	remove(data) {
		return axios.delete(`${this.URL}/${data.id}`);
	},
	activate(data) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/activate`, {params: data});
	},
	archive(data) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/archive`, {params: data});
	},
	shortList(data) {
		return axios.get(`${this.URL}/minimal_data`, {params: {...data}});
	},
	canBeDeleted(data) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/is_can_be_deleted`);
	}
}

axios.interceptors.request.use(
	function (config) {
		if (STORAGE.hasToken()) {
			if (config && config.params && config.params.hashId) {

			} else {
				config.headers['Authorization'] = `Bearer ${STORAGE.getToken()}`;
			}
		}

		config.headers['Accept-Language'] = axios.interceptors.request.customLocale;

		return config;
	}, function (error) {
		return Promise.reject(error);
	}
);

export const ENV = {
	TG: {
		loginRU 		: ISPROD ? 'paywall_login_bot' : (ISFEATURE ? 'PaywallFeatureLoginBot' : 'PaywallDevLoginBot'),
		loginEN 		: ISPROD ? 'paywall_login_bot' : (ISFEATURE ? 'PaywallFeatureLoginBot' : 'PaywallDevLoginBot'),
		loginPartnerRU 	: ISPROD ? 'paywall_login_partner_bot' : (ISFEATURE ? 'PaywallFeatureLoginPartnerBot' : 'PaywallDevLoginPartnerBot'),
		loginPartnerEN 	: ISPROD ? 'paywall_login_partner_bot' : (ISFEATURE ? 'PaywallFeatureLoginPartnerBot' : 'PaywallDevLoginPartnerBot'),

		managerRU 	    : ISPROD ? 'Paywall_manager_bot' : (ISFEATURE ? 'PaywallFeatureManagerBot' : 'PaywallDevManagerBot'),
		managerEN 	    : ISPROD ? 'Paywall_manager_bot' : (ISFEATURE ? 'PaywallFeatureManagerBot' : 'PaywallDevManagerBot'),
		payRU 		    : ISPROD ? 'Paywall_paybot' : (ISFEATURE ? 'PaywallFeaturePayRuBot' : 'PaywallDevPayRuBot'),
		payEN 		    : ISPROD ? 'paywall_en_paybot' : (ISFEATURE ? 'PaywallFeaturePayEnBot' : 'PaywallDevPayEnBot')
	},
	YM	: 65592334,
	GA	: 'UA-172613527-1'

}
